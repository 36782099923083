import React, { useState, useRef, useEffect } from 'react';
import { sendFormData } from '../services/api.js';
import HeroImage from './HeroImage.js';
import FerienhausTeaser from './FerienhausTeaser.js';
import '../styles/styles.js';
import '../styles/anfrage.css';

import messageOK from '../assets/img/logo-trans.png';

function Anfrage() {
  const formRef = useRef(null);

  const [formData, setFormData] = useState({
    anreise_am: '',
    abreise_am: '',
    personen: '',
    name: '',
    vorname: '',
    strasse: '',
    hausnummer: '',
    plz: '',
    ort: '',
    email: '',
    telefonnummer: '',
    bemerkung: ''
  });

  const [formValid, setFormValid] = useState(false);

  const [showSuccessMessage, setShowSuccessMessage] = useState(false);

  const handleReset = () => {
    formRef.current.reset();
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (formValid) {
      try {
        const responseData = await sendFormData(formData, false);
        if (responseData.message === 'Erfolgreich versendet') {
          setShowSuccessMessage(true);
        } else {
          console.error(responseData);
          alert('Es ist ein Fehler aufgetreten! Bitte versuchen Sie es später erneut oder kontaktieren Sie uns telefonisch. Vielen Dank.');
        }
        setFormData({
          lname: '',
          fname: '',
          telefon: '',
          email: '',
          nachricht: ''
        });
      } catch (error) {
        console.error('Fehler beim Senden des Formulars:', error);
        alert('Es ist ein Fehler aufgetreten! Bitte versuchen Sie es später erneut oder kontaktieren Sie uns telefonisch. Vielen Dank.');
      }
    }
  };

  useEffect(() => {
    const isValid = Object.values(formData).every(val => val.trim() !== '');
    setFormValid(isValid);
  }, [formData]);

  return (
    <div>
      <HeroImage />
      <section className="page-content">
        <div className="container-fluid with-marginal">
          <div className="row">
            <div className="col">
              {showSuccessMessage ?
                <div>
                  <h1>Vielen Dank</h1>
                  <br /><br />
                  <p>Ihre Anfrage wurde erfolgreich &uuml;bermittelt.</p>
                  <p>Sobald wir diese gepr&uuml;ft haben, geben wir Ihnen R&uuml;ckmeldung.</p>
                  <br />
                  <section className="hero-img"><img src={messageOK} title="logo" alt="" style={{ width: '30%' }} /></section>
                  <br />
                </div>
                :
                <section className='panel'>
                  <header className='panel-heading' style={{ position: 'relative' }}>
                    <span>Buchungsanfrage</span> <b>Ferienhaus Erste Meile</b>
                  </header>
                  <div className='panel-body'>
                    <form className="form-horizontal request " id="anfrageform" onSubmit={handleSubmit} style={{ marginTop: 0 }} ref={formRef}>
                      <div className='row'>
                        <div className='has-feedback blue zeitraum col-sm-4'>
                          <label className='control-label' for='anreise_am'>von&nbsp;<span className='pflichtfeld'>&#10034;</span></label>
                          <input size='10' name='anreise_am' id='anreise_am' value={formData.anreise_am} onChange={handleChange} className='form-control kalender' type='date' placeholder='tt.mm.jjjj' required />
                          <span className='glyphicon glyphicon-calendar form-control-feedback'></span>
                        </div>
                        <div className='has-feedback blue zeitraum col-sm-4 spacer-xs'>
                          <label className='control-label' for='abreise_am'>bis&nbsp;<span className='pflichtfeld'>&#10034;</span></label>
                          <input size='10' name='abreise_am' id='abreise_am' value={formData.abreise_am} onChange={handleChange} className='form-control kalender' type='date' placeholder='tt.mm.jjjj' required />
                          <span className='glyphicon glyphicon-calendar form-control-feedback'></span>
                        </div>
                        <div className='has-feedback blue personen col-sm-4 spacer-xs'>
                          <label className='control-label' for='personen'>Anzahl Personen&nbsp;<span className='pflichtfeld'>&#10034;</span></label>
                          <input size='10' name='personen' id='personen' value={formData.personen} onChange={handleChange} className='form-control' type='number' required />
                          <span className='glyphicon glyphicon-calendar form-control-feedback'></span>
                        </div>
                      </div>
                      <hr />
                      <div className='form-group'>
                        <label for='vorname' className='col-sm-2 control-label obligation'>Vorname&nbsp;<span className='pflichtfeld'>&#10034;</span></label>
                        <div className='col-sm-10'>
                          <input type='text' className='form-control' name='vorname' value={formData.vorname} onChange={handleChange} id='vorname' required />
                        </div>
                      </div>
                      <div className='form-group'>
                        <label for='name' className='col-sm-2 control-label obligation'>Name&nbsp;<span className='pflichtfeld'>&#10034;</span></label>
                        <div className='col-sm-10'>
                          <input type='text' className='form-control' name='name' value={formData.name} onChange={handleChange} id='name' required />
                        </div>
                      </div>
                      <div className='form-group'>
                        <label for='strasse' className='col-sm-2 control-label obligation'>Stra&szlig;e/HNr&nbsp;<span className='pflichtfeld'>&#10034;</span></label>
                        <div className='col-xs-9 col-sm-7'>
                          <input type='text' className='form-control' name='strasse' value={formData.strasse} onChange={handleChange} id='strasse' required />
                        </div>
                        <div className='col-xs-3 col-sm-3'>
                          <input type='text' className='form-control' name='hausnummer' value={formData.hausnummer} onChange={handleChange} id='hausnummer' required />
                        </div>
                      </div>
                      <div className='form-group'>
                        <label for='mailZip' className='col-sm-2 col-xs-10 control-label obligation'>PLZ/Ort&nbsp;<span className='pflichtfeld'>&#10034;</span></label>
                        <div className='col-xs-3 col-sm-3'>
                          <input type='number' pattern='[0-9]{5}' className='form-control' name='plz' value={formData.plz} onChange={handleChange} id='plz' required />
                        </div>
                        <div className='col-xs-9 col-sm-7'>
                          <input type='text' className='form-control' name='ort' value={formData.ort} onChange={handleChange} id='ort' required />
                        </div>
                      </div>
                      <div className='form-group'>
                        <label for='email' className='col-sm-2 control-label obligation'>Email&nbsp;<span className='pflichtfeld'>&#10034;</span></label>
                        <div className='col-sm-10'>
                          <input type='email' className='form-control' name='email' value={formData.email} onChange={handleChange} id='email' required />
                        </div>
                      </div>
                      <div className='form-group'>
                        <label for='mailPhone' className='col-sm-2 control-label '>Telefon&nbsp;<span className='pflichtfeld'>&#10034;</span></label>
                        <div className='col-sm-10'>
                          <input type='tel' pattern='[0-9 \+\(\)\/-]+' className='form-control' name='telefonnummer' value={formData.telefonnummer} onChange={handleChange} id='telefonnummer' required />
                        </div>
                      </div>
                      <div className='form-group'>
                        <label for='mailNotice' className='col-sm-2 control-label'>Bemerkung</label>
                        <div className='col-sm-10'>
                          <textarea name='bemerkung' id='bemerkung' className='form-control' value={formData.bemerkung} onChange={handleChange} rows='3'></textarea>
                        </div>
                      </div>
                      <div className='form-group spacerForm'>
                        <div className='col-md-offset-2 col-md-10'>
                          <button type='submit' className='btn btn-primary' disabled={!formValid}>Abschicken</button>
                          <button type='button' className='btn btn-default' onClick={handleReset}>Zur&uuml;cksetzen</button>
                        </div>
                      </div>
                      <br />
                      <br />
                      <p>Bitte f&uuml;llen Sie alle markierten Felder aus, damit wir Ihre Anfrage bearbeiten und Ihnen R&uuml;ckmeldung geben k&ouml;nnen.</p>
                    </form>
                  </div>
                </section>
              }
            </div>
            <FerienhausTeaser />
          </div>
        </div>
      </section>
    </div>
  );
};

export default Anfrage;
