import React from 'react';
import HeroImage from './HeroImage';
import FerienhausTeaser from './FerienhausTeaser';
import '../styles/styles.js';

const Belegung = () => {
  return (
    <div>
      <HeroImage />
      <section className="page-content">
        <div className="container-fluid with-marginal">
          <div className="row">
            <div className="col">
              <section className="section-intro bg-light">
                <div className="container-fluid wrapper">
                <h1>der aktuelle Belegungsplan</h1>
                  <iframe src="https://www.ferienwohnungen.de/widget/belegungsplan/?id=102801&amp;months=12&amp;background=ffffff&amp;text=191919" title="Belegung" width="100%" border="0" scrolling="auto" marginWidth="0" marginHeight="0" frameBorder="0" style={{display: 'block'}} height="600px"></iframe>
                </div>
              </section>
            </div>
            <FerienhausTeaser />
          </div>
        </div>
      </section>
    </div>
  );
};

export default Belegung;
