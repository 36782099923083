import React from 'react';
import '../../styles/styles.js';

const FerienhausTabBelegung = () => {
  return (
    <div className="tab-pane fade show active" id="belegung" role="tabpanel" aria-labelledby="belegung-tab">
      <div className="wrapper">
        <iframe title="Test" src="https://www.ferienwohnungen.de/widget/belegungsplan/?id=102801&amp;months=12&amp;background=ffffff&amp;text=191919" width="100%" border="0" scrolling="auto" marginWidth="0" marginHeight="0" frameBorder="0" style={{ display: 'block' }} height="600px"></iframe>
      </div>
    </div>
  );
};

export default FerienhausTabBelegung;
