import React from 'react';
import '../../styles/styles.js';

const FerienhausTabsPreise = () => {
  return (
    <div className="tab-pane fade" id="preise" role="tabpanel" aria-labelledby="preise-tab">
      <div className="wrapper">
        <h3>Preise<small> (ab 2 &Uuml;bernachtungen)</small></h3>
        <table style={{ width: '100%' }} className="table table-striped table-responsive-sm preisemd">
          <tr>
            <td valign="top" nowrap="nowrap">Preise für 2 Personen:</td>
            <td valign="top" nowrap="nowrap"></td>
          </tr>
          <tr>
            <td valign="top" nowrap="nowrap"></td>
            <td valign="top" nowrap="nowrap">€ 75,- pro Nacht</td>
          </tr>
          <tr>
            <td valign="top" nowrap="nowrap">jede weitere Person</td>
            <td valign="top" nowrap="nowrap"></td>
          </tr>
          <tr>
            <td valign="top" nowrap="nowrap"></td>
            <td valign="top" nowrap="nowrap">€ 15,- pro Nacht</td>
          </tr>
        </table>
        <br />
        <p><b>Preis für eine Übernachtung bitte telefonisch erfragen.</b></p>
        <br />
        <h3>Allgemeines</h3>
        <br />
        <p>Bettwäsche, Handtücher und Endreinigung inklusive</p>
        <p>WLAN Nutzung frei</p>
        <br />
      </div>
    </div>
  );
};

export default FerienhausTabsPreise;
